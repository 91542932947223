<template>
  <canvas
    ref="canvas"
    class="reaction-canvas absolute inset-0" />
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import type { ReactionInstance } from "~/stores/canvas";
import { reactionConfig } from "~/stores/canvas";

const canvas = ref<HTMLCanvasElement>();
const ctx = ref<CanvasRenderingContext2D>();
const images = ref<Record<string, HTMLCanvasElement[]>>( {} );
const reactions = ref<ReactionState[]>( [] );
const store = useCanvasStore();
let lastTimestamp = 0;
let dpr = 1;

export type ReactionState = ReactionInstance & {
  x: number;
  y: number;
};

watch( store.reactions, ( val ) => {
  reactions.value.push( ...val.map<ReactionState>( ( x ) => {
    const foo = structuredClone( toRaw( x ) ) as ReactionState;

    foo.y = ( canvas.value?.height ?? 0 ) / dpr; // Adjust by DPR
    foo.x = ( ( foo.startX / 100 ) * ( canvas.value?.width ?? 0 ) ) / dpr; // Adjust by DPR

    return foo;
  } ) );

  val.length = 0;
} );

const createSVGImage = ( svgString: string, color: string, size: number ): Promise<HTMLCanvasElement> => {
  const coloredSVG = svgString.replace( "<path ", `<path fill="${color}" ` );
  const svg = new Blob( [coloredSVG], { type: "image/svg+xml" } );
  const url = URL.createObjectURL( svg );
  const img = new Image();
  img.src = url;

  return new Promise( ( resolve ) => {
    img.onload = () => {
      const offscreenCanvas = document.createElement( "canvas" );
      const dpr = window.devicePixelRatio || 1;
      offscreenCanvas.width = size * dpr;
      offscreenCanvas.height = size * dpr;
      const offscreenCtx = offscreenCanvas.getContext( "2d" );

      if ( !offscreenCtx ) {
        throw new Error( "Unable to get offscreen context" );
      }

      offscreenCtx.scale( dpr, dpr );
      offscreenCtx.drawImage( img, 0, 0, size, size );
      URL.revokeObjectURL( url );
      resolve( offscreenCanvas );
    };
  } );
};

const preloadImages = async () => {
  for ( const type in reactionConfig ) {
    images.value[type] = [];
    const config = reactionConfig[type];

    for ( const svg of config.svgs ) {
      for ( const color of config.colors ) {
        images.value[type].push( await createSVGImage( svg, color, 200 ) );
      }
    }
  }
};

const drawReactions = ( timestamp = 0 ) => {
  if ( !canvas.value || !ctx.value ) return;

  if ( !lastTimestamp ) {
    lastTimestamp = timestamp;
  }

  const elapsed = timestamp - lastTimestamp; // Time elapsed since the last frame in milliseconds
  lastTimestamp = timestamp;

  ctx.value.clearRect( 0, 0, canvas.value.width, canvas.value.height );

  const remainingReactions: ReactionState[] = [];
  const time = Date.now() / 1000;

  reactions.value.forEach( ( reaction ) => {
    const config = reactionConfig[reaction.type];
    config.movement( reaction, time );
    const image = images.value[reaction.type][reaction.imageIndex ?? 0];

    const angleRadians = reaction.rotation * Math.PI / 180;

    ctx.value!.save();
    ctx.value!.translate( reaction.x, reaction.y );
    ctx.value!.rotate( angleRadians );
    ctx.value!.drawImage( image, -reaction.size / 2, -reaction.size / 2, reaction.size, reaction.size );
    ctx.value!.restore();

    // Calculate movement based on speed and elapsed time
    const movementDistance = reaction.speed * ( elapsed / 1000 ); // Convert elapsed time from milliseconds to seconds
    reaction.y -= movementDistance;

    // Keep reaction if it hasn't moved off the top
    if ( reaction.y + reaction.size >= 0 ) {
      remainingReactions.push( reaction );
    }
  } );

  // Update reactions with only the remaining ones
  reactions.value = remainingReactions;

  requestAnimationFrame( drawReactions );
};

const onResize = () => {
  if ( !canvas.value || !ctx.value ) return;

  const dpr = window.devicePixelRatio || 1;
  const newWidth = canvas.value.clientWidth;
  const newHeight = canvas.value.clientHeight;

  // Save old internal dimensions
  const oldWidth = canvas.value.width;
  const oldHeight = canvas.value.height;

  // Set the canvas internal size to the client size multiplied by the DPR
  canvas.value.width = newWidth * dpr;
  canvas.value.height = newHeight * dpr;

  // Scale the drawing context to ensure elements are drawn correctly
  ctx.value.setTransform( dpr, 0, 0, dpr, 0, 0 );

  const xScale = ( newWidth * dpr ) / oldWidth;
  const yScale = ( newHeight * dpr ) / oldHeight;

  reactions.value.forEach( ( reaction ) => {
    reaction.x *= xScale;
    reaction.y *= yScale;
  } );
};

onMounted( async () => {
  if ( !canvas.value ) return;

  dpr = window.devicePixelRatio;
  ctx.value = canvas.value.getContext( "2d" ) ?? undefined;
  onResize();
  await preloadImages();
  drawReactions();

  useEventListener( window, "resize", onResize );
} );
</script>

<style scoped>
.reaction-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
